import { Item } from '@/data/@types/Item'
import { SearchHits } from '@/data/@types/SearchHits'
import { requestQuery } from '@/data/@types/requestQueryData'
import { SimpleSearchRequest } from '@/data/@types/SimpleSearchRequest'
import { CollectionDetailSearchRequest } from '@/data/@types/CollectionDetailSearchRequest'
import { getData, postData } from '@/helpers/util/webApiUtil'
import { FullTextSearchRequest } from '@/data/@types/FulltextSearchRequest'
import { FullTextSearchResult } from '@/data/@types/FulltextSearchResult'
import { ActionContext } from 'vuex'
import { convertSingleArrayToString } from '@/helpers/util/queryUtils'
import { Bucket } from '@/data/@types/Aggregations'

const querystring = require('querystring') // eslint-disable-line

const BASE_URL = process.env.VUE_APP_API_BASE_URL

interface State {
  SearchHits: any;
  SearchRequestQuery: any;
  SimpleSearchQuery: any;
  CollectionDetailSearchQuery: any;
  CacheQuery: any;
  CacheCollection: string;
  CollectionSummaryResponse: any;
  NarrowSearchQuery: any;
  isNarrowed: boolean;
  isDisable: boolean;
  isAllowed: boolean;
  isBundleAllowed: boolean;
  isContentAllowed: boolean;
  isAllowedPrint: boolean;
  isAllowedPrintKss: boolean;
  isAllowedPrintInFileViewer: boolean;
  isAllowedSimview: boolean;
  isAllowedDownload: boolean;
  isProcessing: boolean;
  isTooManyRequests: boolean;
  hasError: boolean;
  openClosure: boolean;
  keyword: string;
  fulltextSnippet: any;
}

type Context = ActionContext<State, any>

/**
 * ステート
 */

const state: State = {
  SearchHits: {
    searchHits: [],
  },
  SearchRequestQuery: {},
  SimpleSearchQuery: {},
  CollectionDetailSearchQuery: {},
  CacheQuery: {},
  CacheCollection: '',
  CollectionSummaryResponse: {},
  NarrowSearchQuery: {},
  isNarrowed: false,
  isDisable: false,
  isAllowed: false,
  isBundleAllowed: false,
  isContentAllowed: false,
  isAllowedPrint: false,
  isAllowedPrintKss: false,
  isAllowedPrintInFileViewer: false,
  isAllowedSimview: false,
  isAllowedDownload: false,
  isProcessing: false,
  isTooManyRequests: false,
  hasError: false,
  openClosure: true,
  keyword: '',
  /** 全文スニペット用 */
  fulltextSnippet: {},
}

/**
 * ゲッター
 */

const getters = {
  ItemList (state: State) {
    return state.SearchHits.searchHits ? state.SearchHits.searchHits : []
  },
  totalHits (state: State): number {
    return state.SearchHits.totalHits ? state.SearchHits.totalHits : 0
  },
  facetList (state: State) {
    if (state.SearchHits.aggregations) {
      //  NOTE: ここのgetterは、computed で参照している箇所が多いく、
      //  stateを直接更新すると都度 computed が発火するため、objectをcloneする
      const _clone = JSON.parse(JSON.stringify(state.SearchHits.aggregations.asMap))
      const haveChildHierarchyFacets = ['digitizedContents', 'onlinePublications', 'ndcDivisions']
      haveChildHierarchyFacets.forEach(facet => {
        deleteDuplicateChildCollection(_clone[facet]?.buckets)
      })
      return _clone
    } else {
      return {}
    }
  },
  isNarrowed (state: State): boolean {
    return state.isNarrowed
  },
  isDisable (state: State): boolean {
    return state.isDisable
  },
  isAllowed (state: State): boolean {
    return state.isAllowed
  },
  isBundleAllowed (state: State): boolean {
    return state.isBundleAllowed
  },
  isContentAllowed (state: State): boolean {
    return state.isContentAllowed
  },
  isAllowedPrint (state: State): boolean {
    return state.isAllowedPrint
  },
  isAllowedPrintKss (state: State): boolean {
    return state.isAllowedPrintKss
  },
  isAllowedPrintInFileViewer (state: State): boolean {
    return state.isAllowedPrintInFileViewer
  },
  isAllowedSimview (state: State): boolean {
    return state.isAllowedSimview
  },
  isAllowedDownload (state: State): boolean {
    return state.isAllowedDownload
  },
  isTooManyRequests (state: State): boolean {
    return state.isTooManyRequests
  },
  hasErrorInSearchResultPage (state: State): boolean {
    return state.hasError
  },
  requestQuery (state: State) {
    return state.SearchRequestQuery
  },
  simpleSearchQuery (state: State) {
    return state.SimpleSearchQuery
  },
  collectionDetailSearchQuery (state: State) {
    return state.CollectionDetailSearchQuery
  },
  cacheQuery (state: State) {
    return state.CacheQuery
  },
  cacheCollection (state: State): string {
    return state.CacheCollection
  },
  collectionSummaryResponse (state: State) {
    return state.CollectionSummaryResponse
  },
  narrowSearchQuery (state: State) {
    return state.NarrowSearchQuery
  },
  // メソッドスタイルGetter
  getFulltextSnippet: (state: State) => (pid: string) => {
    const blank = ''
    const prefix = 'info:ndljp/pid/'
    try {
      const pidNum = pid.startsWith(prefix) ? pid.replace(prefix, blank) : pid
      return state.fulltextSnippet[pidNum]
    } catch {
      return blank
    }
  },
  openClosure (state: State): boolean {
    return state.openClosure
  },
  getInputKeyword (state: State): string {
    return state.keyword
  },
  itemIsProcessing (state: State): boolean {
    return state.isProcessing
  },
}

/**
 * ミューテーション
 */

const mutations = {
  INIT_META_SEARCH (state: State): void {
    state.SearchHits = {
      searchHits: [],
    }
    state.isTooManyRequests = false
    state.hasError = false
  },
  META_SEARCH (state: State, payload: SearchHits<Item>): void {
    state.SearchHits = payload
    state.isTooManyRequests = false
    state.hasError = false
    console.log('META_SEARCH', state)
  },
  SEARCH_QUERY (state: State, query: requestQuery): void {
    state.SearchRequestQuery = query
    console.log('SEARCH_QUERY', state.SearchRequestQuery)
  },
  SIMPLE_SEARCH_QUERY (state: State, query: SimpleSearchRequest): void {
    state.SimpleSearchQuery = query
    console.log('SIMPLE_SEARCH_QUERY', state.SimpleSearchQuery)
  },
  COLLECTION_DETAIL_SEARCH_QUERY (state: State, query: CollectionDetailSearchRequest): void {
    state.CollectionDetailSearchQuery = query
    console.log('COLLECTION_DETAIL_SEARCH_QUERY', state.CollectionDetailSearchQuery)
  },
  COLLECTION_SUMMARY_RESPONSE (state: State, response: any): void {
    console.log('COLLECTION_SUMMARY_RESPONSE: ', response)
    state.CollectionSummaryResponse = response
  },
  NARROW_SIMPLE_SEARCH_QUERY (state: State, query: any): void {
    state.NarrowSearchQuery = query
    console.log('NARROW_SIMPLE_SEARCH_QUERY', state.NarrowSearchQuery)
  },
  CACHE_QUERY (state: State, query: any): void {
    state.CacheQuery = convertSingleArrayToString(query)
  },
  CACHE_COLLECTION (state: State, collection: string): void {
    state.CacheCollection = collection
  },
  NARROW_DOWN (state: State): void {
    state.isNarrowed = true
  },
  RESET_NARROWING_DOWN (state: State): void {
    state.isNarrowed = false
  },
  ISDISABLE (state: State, isDisable: boolean): void {
    state.isDisable = isDisable
  },
  ALLOWED (state: State, isAllowed: boolean): void {
    state.isAllowed = isAllowed
  },
  BUNDLE_ALLOWED (state: State, isAllowed: boolean): void {
    state.isBundleAllowed = isAllowed
  },
  CONTENT_ALLOWED (state: State, isAllowed: boolean): void {
    state.isContentAllowed = isAllowed
  },
  PRINT_ALLOWED (state: State, isAllowed: boolean): void {
    state.isAllowedPrint = isAllowed
  },
  PRINT_KSS_ALLOWED (state: State, isAllowed: boolean): void {
    state.isAllowedPrintKss = isAllowed
  },
  PRINT_FILEVIEWER_ALLOWED (state: State, isAllowed: boolean): void {
    state.isAllowedPrintInFileViewer = isAllowed
  },
  SIMVIEW_ALLOWED (state: State, isAllowed: boolean): void {
    state.isAllowedSimview = isAllowed
  },
  DOWNLOAD_ALLOWED (state: State, isAllowed: boolean): void {
    state.isAllowedDownload = isAllowed
  },
  PROCESSING (state: State): void {
    state.isProcessing = true
  },
  PROCESSED (state: State): void {
    state.isProcessing = false
  },
  TOO_MANY_REQUESTS (state: State): void {
    state.isTooManyRequests = true
  },
  ERROR (state: State): void {
    state.hasError = true
  },
  SNIPPET (state: State, payload: { [id: string]: FullTextSearchResult }): void {
    state.fulltextSnippet = payload
  },
  SNIPPET_RESET (state: State): void {
    state.fulltextSnippet = {}
  },
  SET_OPEN_CLOSURE (state: State, flag: boolean): void {
    state.openClosure = flag
  },
  SET_INPUT_KEYWORD (state: State, keyword: string): void {
    state.keyword = keyword
  },
}

/**
 * アクション
 */
const actions = {
  async checkPermission (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('ALLOWED', response.value))
  },
  async checkBundlePermission (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('BUNDLE_ALLOWED', response.value))
  },
  async checkContentPermission (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('CONTENT_ALLOWED', response.value))
  },
  async checkPrint (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('PRINT_ALLOWED', response.value))
  },
  async checkPrintKss (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('PRINT_KSS_ALLOWED', response.value))
  },
  async checkPrintInFileViewer (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('PRINT_FILEVIEWER_ALLOWED', response.value))
  },
  async checkSimView (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('SIMVIEW_ALLOWED', response.value))
  },
  async checkDownload (context: Context, payload: { type: string, action: string }): Promise<void> {
    await getData(`${BASE_URL}/check/allowedContents/${payload.type}/${payload.action}`).then(response => context.commit('DOWNLOAD_ALLOWED', response.value))
  },
  async sendLogToPms (context: Context): Promise<void> {
    postData(`${BASE_URL}/auth/external/proxy/pmsapi/opatron/add-service-usage`)
  },

  async detailedSearch (context: Context, request: any): Promise<void> {
    await itemSearch(context, request)
  },

  async simpleSearch (context: Context, request: SimpleSearchRequest): Promise<void> {
    await itemSearch(context, request)
  },

  async collectionDetailSearch (context: Context, request: CollectionDetailSearchRequest): Promise<void> {
    await itemSearch(context, request)
  },

  async collectionSummarySearch (context: Context, collectionId: string): Promise<void> {
    const url = `${BASE_URL}/collection/info/${collectionId}`
    // TODO エラーハンドリング
    try {
      const response = await getData(url)
      context.commit('COLLECTION_SUMMARY_RESPONSE', response)
    } catch (error: any) {
      context.commit('ERROR')
    }
  },

  async snippetSearch (context: Context, keywords: string): Promise<void> {
    await fetchFulltextSnippet(context, keywords)
  },
}

// TODO POST /item/searchのリクエストでrequestの型付け
const itemSearch = async (context: Context, request: any) => {
  context.commit('INIT_META_SEARCH')
  if (!request.sortKey) {
    request.sortKey = 'SCORE'
    request.order = 'DESC'
  }
  // 巻号のチェックボックスの真偽値を反転して詰直す
  if (request.excludeVolumeNum === undefined) request.excludeVolumeNum = !request.includeVolumeNum
  // リクエストには不要なので削除
  delete request.includeVolumeNum
  context.commit('PROCESSING')
  context.commit('SEARCH_QUERY', request)
  const url = `${BASE_URL}/item/search`
  collectionFacetConvertToArray(request)
  if (isNarrowedDown(request)) {
    context.commit('NARROW_DOWN')
  } else {
    context.commit('SIMPLE_SEARCH_QUERY', request)
    context.commit('COLLECTION_DETAIL_SEARCH_QUERY', request)
    context.commit('RESET_NARROWING_DOWN')
  }
  context.commit('NARROW_SIMPLE_SEARCH_QUERY', request)
  // TODO エラーハンドリング
  try {
    const response = await postData(url, request)
    context.commit('META_SEARCH', response)

    if (request.keyword && request.fullText) {
      // 全文検索実行時、非同期にスニペット検索を実行する
      fetchFulltextSnippet(context, request.keyword, request.fullTextInterval, request.ftInterval)
    } else {
      context.commit('SNIPPET_RESET')
    }
  } catch (error: any) {
    if ((error as Error)?.message === '429') {
      context.commit('TOO_MANY_REQUESTS')
    }
    context.commit('ERROR')
  } finally {
    context.commit('PROCESSED')
  }
}

const extractPidNum = (pid: string) => {
  const blank = ''
  const prefix = 'info:ndljp/pid/'
  return pid.startsWith(prefix) ? pid.replace(prefix, blank) : pid
}

const fetchFulltextSnippet = async (context: Context, keyword: string, fullTextInterval = false, ftInterval = 400) => {
  context.commit('SNIPPET_RESET')

  const snippetUrl = `${BASE_URL}/fulltext/search`
  const targets = state.SearchHits.searchHits
    .filter((hit: any) => hit.content && hit.content.type === 'leaf' && hit.content.rules.snippet && hit.content.contentsBundles)
    .map(
      (searchHits: SearchHits<Item>['searchHits']) => {
        return {
          pid: extractPidNum(searchHits.content.pid), // 自然数部分のみ
          bids: searchHits.content.contentsBundles.map((obj: any) => obj.id),
        }
      }
    )
  const sp = ' '
  const FullWidthSpaceRegExp = /\u3000/g
  const keywords = keyword.replace(FullWidthSpaceRegExp, sp).split(sp)
  const requestBody: FullTextSearchRequest = {
    keyword,
    keywords, //  TODO: Legacy: 2025/01のリリース以降不要となる。
    targets,
    mode: 'SNIPPET',
    sort: 'SCORE',
    size: 10,
    fullTextInterval,
    ftInterval,
  }
  try {
    if (targets.length === 0) return
    const snippetSearchHits = await postData(snippetUrl, requestBody)
    context.commit('SNIPPET', snippetSearchHits)
  } catch (error: any) {
    // TODO エラーハンドリング
    console.error(error.message)
    context.commit('SNIPPET_RESET')
  }
}

const collectionFacetConvertToArray = (request: any): void => {
  if (request.collection_facet && request.collection_facet.length) {
    if (request.collection_facet.length === 1) {
      // TODO collection_facetが文字列とならないための恒久対応
      request.collection_facet = request.collection_facet[0].split(',')
    }
  }
}

const isNarrowedDown = (request: any) => {
  if (request.collection_facet && request.collection_facet.length) return true
  if (request.permission_facet && request.permission_facet.length) return true
  if (request.ndc_facet && request.ndc_facet.length) return true
  if (request.rekion_facet && request.rekion_facet.length) return true
  if (request.basis_facet && request.basis_facet.length) return true
  if (request.levelCode_facet && request.levelCode_facet.length) return true
  if (request.official_facet && request.official_facet.length) return true
  if (request.article_facet && request.article_facet.length) return true
  if (request.institution_facet && request.institution_facet.length) return true
  if (request.subject_facet && request.subject_facet.length) return true
  if (request.language_facet && request.language_facet.length) return true
  if (request.classic_facet && request.classic_facet.length) return true
  if (request.ghq_facet && request.ghq_facet.length) return true
  if (request.publishEra_facet && request.publishEra_facet.length) return true
  if (request.publishYear_facet && request.publishYear_facet.length) return true
  if (request.fromYear_facet) return true
  if (request.toYear_facet) return true
  if (request.itemToSearch_facet && request.itemToSearch_facet.length) return true
  if (request.materialType_facet && request.materialType_facet.length) return true
  if (request.session_facet && request.session_facet.length) return true
  if (request.house_facet && request.house_facet.length) return true
  return false
}

const deleteDuplicateChildCollection = (buckets: Array<Bucket>) => {
  if (buckets == null) return
  buckets.forEach((bucket: Bucket) => {
    if (bucket.children != null) {
      bucket.children = Array.from(
        new Map(bucket.children.map((children) => [children.keyAsString, children])).values()
      )
    }
  })
}

export default {
  state,
  actions,
  mutations,
  getters,
}
