import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d458bfc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "video-viewer-toolbar-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "audio-viewer"
}
const _hoisted_3 = { class: "audio-viewer-image level-center is-mobile" }
const _hoisted_4 = { class: "level-center is-mobile" }
const _hoisted_5 = { class: "audio-viewer-toolbar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoViewerToolbar = _resolveComponent("VideoViewerToolbar")!
  const _component_AppImg = _resolveComponent("AppImg")!
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!

  return (_openBlock(), _createElementBlock("div", {
    id: "video-component",
    class: _normalizeClass(["level-center is-mobile", `contenet-data-${_ctx.currentContentId}`]),
    onMousemove: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMobile ? false : (_ctx.state.showToolbar = true)))
  }, [
    _withDirectives(_createElementVNode("div", {
      class: "theoplayer-container video-js theoplayer-skin THEOplayer",
      onTouchstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.showToolbar = !_ctx.state?.showToolbar))
    }, null, 544), [
      [_vShow, _ctx.currentBundleType === 'movie']
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.currentBundleType === 'movie')
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_VideoViewerToolbar, {
                item: _ctx.item,
                state: _ctx.state,
                onMousemove: _ctx.renewTimeout,
                onPauseAction: _ctx.pauseAction,
                onPlay: _ctx.play,
                onPause: _ctx.pause,
                onPrev: _ctx.prev,
                onNext: _ctx.next,
                onSeekbar: _ctx.seekbar,
                onBackward: _ctx.backward,
                onForward: _ctx.forward,
                onChangeSpeed: _ctx.changeSpeed,
                onToggleContinuous: _ctx.toggleContinuous,
                onUnmute: _ctx.unmute,
                onMute: _ctx.mute
              }, null, 8, ["item", "state", "onMousemove", "onPauseAction", "onPlay", "onPause", "onPrev", "onNext", "onSeekbar", "onBackward", "onForward", "onChangeSpeed", "onToggleContinuous", "onUnmute", "onMute"])
            ], 512)), [
              [_vShow, _ctx.state?.showToolbar || true]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.currentBundleType === 'audio')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("figure", _hoisted_4, [
              (_ctx.contentsBundleThumbnail)
                ? (_openBlock(), _createBlock(_component_AppImg, {
                    key: 0,
                    src: _ctx.BASE_URL + _ctx.contentsBundleThumbnail,
                    alt: _ctx.contentsBundleThumbnail
                  }, null, 8, ["src", "alt"]))
                : (_openBlock(), _createBlock(_component_PlaceholderImage, {
                    key: 1,
                    id: "audio-viewer-placeholder",
                    type: "music"
                  }))
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_VideoViewerToolbar, {
              item: _ctx.item,
              media: "audio",
              state: _ctx.state,
              onMousemove: _ctx.renewTimeout,
              onPauseAction: _ctx.pauseAction,
              onPlay: _ctx.play,
              onPause: _ctx.pause,
              onSeekbar: _ctx.seekbar,
              onBackward: _ctx.backward,
              onForward: _ctx.forward,
              onChangeSpeed: _ctx.changeSpeed,
              onToggleContinuous: _ctx.toggleContinuous,
              onUnmute: _ctx.unmute,
              onMute: _ctx.mute
            }, null, 8, ["item", "state", "onMousemove", "onPauseAction", "onPlay", "onPause", "onSeekbar", "onBackward", "onForward", "onChangeSpeed", "onToggleContinuous", "onUnmute", "onMute"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 34))
}