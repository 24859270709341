import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4db1cc09"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "panel-bundle-metadata",
  class: "panel-metadata has-background-contents"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  id: "panel-bundle-metadata-field-list",
  class: "panel-metadata-field-list"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "panel-metadata-field-name has-text-modest" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.heading), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meta, (data, key) => {
        return (_openBlock(), _createElementBlock("li", {
          key: data,
          class: "panel-metadata-field",
          id: `bundle-meta-${key}`
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getMetadataLabels(key)[_ctx.lang]), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.metaArrayProcessing(data)), 1)
        ], 8, _hoisted_4))
      }), 128))
    ])
  ]))
}