
import {
  defineComponent,
  defineAsyncComponent,
  computed,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  reactive,
  watch,
} from 'vue'
import { useStore } from 'vuex'
import HeadstoneItem from '@/components/organisms/ItemContentViewer/HeadstoneItem.vue'
import TheImageViewer from '@/components/TheImageViewer.vue'
import NotPublished from '@/components/organisms/NotPublished.vue'
import SimViewDeny from '@/components/SimViewDeny.vue'
import NoContentViewer from '@/components/NoContentViewer.vue'
import AddonView from '@/components/organisms/ItemContentViewer/AddonView.vue'
import InformationHeader from '@/components/organisms/ItemContentViewer/InformationHeader.vue'
import PanelAbstracts from '@/components/organisms/ItemContentViewer/PanelAbstracts.vue'
import PanelMetadata from '@/components/organisms/ItemContentViewer/PanelMetadata.vue'
import PanelBundleMetadata from '@/components/organisms/ItemContentViewer/PanelBundleMetadata.vue'
import PanelShare from '@/components/organisms/ItemContentViewer/PanelShare.vue'
import ViewerContainer from '@/components/organisms/ItemContentViewer/mobile/ViewerContainer.vue'
import { Splitpanes, Pane } from 'splitpanes'
import md from '@/helpers/util/MobileDetect'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import { Content } from '@/data/@types/Content'
import { AccessTokenService } from '@/store/modules/AccessToken'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import getAutoAdjustParameter from '@/helpers/imageviewer/AutoAdjust'
import { ViewMode } from '@/helpers/imageviewer/ImageViewer'

const BranchViewer = defineAsyncComponent({
  loader: () => import('@/components/organisms/ItemContentViewer/BranchViewer.vue' /* webpackChunkName: "branch-viewer" */),
})

const TheFileViewer = defineAsyncComponent({
  loader: () => import('@/components/TheFileViewer.vue' /* webpackChunkName: "the-file-viewer" */),
})

const TheVideoViewer = defineAsyncComponent({
  loader: () => import('@/components/TheVideoViewer.vue' /* webpackChunkName: "the-video-viewer" */),
})

const PanelIndex = defineAsyncComponent({
  loader: () => {
    return new Promise<any>(resolve => {
      setTimeout(() => {
        resolve(import('@/components/organisms/ItemContentViewer/mobile/PanelIndex.vue' /* webpackChunkName: "panel-index" */))
      }, 300)
    })
  },
})

const PanelDownload = defineAsyncComponent({
  loader: () => import('@/components/organisms/ItemContentViewer/PanelDownload.vue' /* webpackChunkName: "panel-download" */),
})

const PanelRelatedItems = defineAsyncComponent({
  loader: () => {
    return new Promise<any>(resolve => {
      setTimeout(() => {
        resolve(import('@/components/organisms/ItemContentViewer/PanelRelatedItems.vue' /* webpackChunkName: "panel-related-items" */))
      }, 1000)
    })
  },
})

export default defineComponent({
  name: 'ItemViewer',
  components: {
    InformationHeader,
    HeadstoneItem,
    TheImageViewer,
    BranchViewer,
    TheFileViewer,
    NotPublished,
    SimViewDeny,
    AddonView,
    PanelAbstracts,
    PanelMetadata,
    PanelShare,
    PanelDownload,
    PanelRelatedItems,
    TheVideoViewer,
    NoContentViewer,
    PanelIndex,
    ViewerContainer,
    Splitpanes,
    Pane,
    PanelBundleMetadata,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showViewer: {
      type: Boolean,
      required: true,
    },
  },
  setup (props) {
    const isServer = typeof window === 'undefined'
    const store = useStore()
    const route = useRoute()
    const currentBundleIndex = computed(() => store.getters.bundleNumber)
    const currentBundle = computed<ContentsBundle>(
      () => store.getters.currentContentsBundle
    )
    const currentContent = computed<Content>(
      () => store.getters.currentContent
    )
    const currentContentId = computed(
      () => store.getters.contentId
    )
    const isMobile = md.phone()
    const isDisable = ref(false)
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const isIframePage = computed(() => store.getters.isIframe)
    const i18n = useI18n()
    const lang = i18n.locale
    const waitRestrictions = ref(false)
    const addonViewResetToken = ref(0)
    const canSimView = ref(false)
    const isFileBundle = computed(() => (currentBundle.value?.type === 'file') || (currentBundle.value?.type === 'content'))
    const contentIndex = computed(() => route.params.contentIndex)

    // 409はアドオンビューの初期サイズ
    const getPaneRightWidth = () => {
      if (isServer) return 0
      const mql = window.matchMedia('(orientation: portrait)')
      if (mql.matches) {
        return getPaneRightMinWidth()
      }
      if (document.body?.clientWidth) {
        return 409 / document.body.clientWidth * 100
      }
      return 0
    }
    // 17はハンドラーのサイズ
    const getPaneRightMinWidth = () => {
      if (isServer) return 0
      if (document.body?.clientWidth) {
        return 17 / document.body.clientWidth * 100
      }
      return 0
    }
    const getPaneLeftWidth = () => 100 - getPaneRightWidth()
    const getPaneLeftMaxWidth = () => 100 - getPaneRightMinWidth()

    const hasDivideData = computed(() => {
      return store.getters.contents?.some((content: Content) => {
        const devideData = content?.extra?.layout?.divide
        return devideData && devideData > 0 && devideData < 1
      })
    })

    if (hasDivideData.value && Number(localStorage.viewMode) === ViewMode.SINGLE) {
      store.commit('changeViewMode', ViewMode.SINGLE)
    }

    watch(
      () => currentBundle.value?.id,
      () => {
        store.dispatch('checkBundlePermission', { type: bundlePermissionRule.value, action: 'view' })
        store.dispatch('checkPrint', { type: bundlePermissionRule.value, action: 'print' })
        store.dispatch('checkPrintKss', { type: bundlePermissionRule.value, action: 'printKss' })
        store.dispatch('checkDownload', { type: bundlePermissionRule.value, action: 'download' })
      })
    watch(
      () => currentContent.value?.id,
      async () => {
        await store.dispatch('checkContentPermission', { type: contentPermissionRule.value, action: 'view' })
        if (state.completeFetchAccessTokens) {
          // 統計ログを出力するため
          store.commit('GET_META_CONTENTS', { contentMeta: store.getters.item, isRekion: isRekion })
        }
      })

    const detailContentViewSize = computed(() => isRekion ? getPaneLeftMaxWidth() : getPaneLeftWidth())
    const addonViewSize = computed(() => isRekion ? getPaneRightMinWidth() : getPaneRightWidth())
    const addonViewMinSize = computed(() => getPaneRightMinWidth())

    const state = reactive({
      completeFetchAccessTokens: false,
    })

    const viewerType = computed(() => {
      if (isServer) {
        return 'NoContentViewer'
      }
      if (props.item.type === 'branch') {
        if (props.item.contentsBundles && props.item.contentsBundles.length) {
          return 'NoContentViewer'
        } else {
          return 'BranchViewer'
        }
      }
      if (!store.getters.contents?.length) {
        return 'NoContentViewer'
      }
      const type = store.getters.contentType
      if (type === 'image') {
        return 'TheImageViewer'
      } else if (type === 'movie' || type === 'audio') {
        return 'TheVideoViewer'
      } else if (type === 'file' || type === 'content') {
        return 'TheFileViewer'
      } else {
        // TODO コンテンツがない場合の表示方法の確認
        return 'NoContentViewer'
      }
    })

    const permissionRule = computed(() => props.item.permission.rule)
    const isPermission = computed(() => {
      console.log('item = ' + store.getters.isAllowed)
      return store.getters.isAllowed
    })
    const isAllowedSimview = computed(() => store.getters.isAllowedSimview)

    // 自身のルールが未設定の場合は、上位を参照
    const bundlePermissionRule = computed(() => currentBundle.value?.permission?.rule ? currentBundle.value.permission.rule : props.item.permission.rule)
    const isBundlePermission = computed(() => {
      console.log('bundle = ' + store.getters.isBundleAllowed)
      return store.getters.isBundleAllowed
    })

    // 自身のルールが未設定の場合は、上位を参照
    const contentPermissionRule = computed(() => currentContent.value?.permission?.rule ? currentContent.value.permission.rule : bundlePermissionRule.value)
    const isContentPermission = computed(() => {
      console.log('content = ' + store.getters.isContentAllowed)
      return store.getters.isContentAllowed
    })

    const showNotFound = computed(() => {
      if (contentPermissionRule.value === 'handicapped') return true
      if (!isKn && ['shinsai_internet', 'shinsai_ndl_only'].includes(contentPermissionRule.value)) return true
      if ((isKn || isRekion) && contentPermissionRule.value === 'out_of_service') return true
      return false
    })
    const showNotPublished = computed(() => {
      if (isDisable.value || !isPermission.value) return true
      if (isFileBundle.value) {
        if (!isBundlePermission.value && bundlePermissionRule.value.startsWith('ndl_only_package')) return true
      } else {
        if (!isBundlePermission.value || !isContentPermission.value) return true
      }
      return false
    })

    const showDownloadPanel = computed(() => !isRekion && store.getters.isAllowedDownload && store.getters.contentType === 'image' && canSimView.value)
    const isLoggedIn = computed(() => store.getters.isLoggedIn)

    const viewConditionRefresh = async () => {
      if (props.item.type === 'leaf') {
        try {
          isDisable.value = await AccessTokenService.isEmpty()
        } catch (e) {
          console.error('AccessTokenService.isEmpty error')
          console.error(e)
          throw e
        }
      } else {
        isDisable.value = false
      }
      setTimeout(() => {
        waitRestrictions.value = true
        addonViewResetToken.value++
      }, 100)
    }

    watch(
      [isLoggedIn, () => props.item.pid],
      async (next, prev) => {
        if (prev != null && next != null && prev[0] === next[0] && prev[1] === next[1]) return
        waitRestrictions.value = false
        state.completeFetchAccessTokens = false
        const setTokenAsync = async () => {
          try {
            await AccessTokenService.setAccessTokenAsync(props.item.pid)
          } catch (e) {
            console.error('AccessTokenService.setAccessTokenAsync error 1')
            console.error(e)
            throw e
          }
        }
        await Promise.all([
          store.dispatch('checkPermission', { type: permissionRule.value, action: 'view' }),
          store.dispatch('checkBundlePermission', { type: bundlePermissionRule.value, action: 'view' }),
          store.dispatch('checkContentPermission', { type: contentPermissionRule.value, action: 'view' }),
          store.dispatch('checkPrint', { type: bundlePermissionRule.value, action: 'print' }),
          store.dispatch('checkPrintKss', { type: bundlePermissionRule.value, action: 'printKss' }),
          store.dispatch('checkDownload', { type: bundlePermissionRule.value, action: 'download' }),
          setTokenAsync(),
        ]).then(viewConditionRefresh)

        store.commit('ISDISABLE')
        state.completeFetchAccessTokens = true
        // 統計ログを出力するため
        store.commit('GET_META_CONTENTS', { contentMeta: store.getters.item, isRekion: isRekion })
        // 「提供範囲種別」が「図書館・個人送信(ooc)」のアイテムを図書館・個人送信ユーザが館外から閲覧したログをPMSへ連携するため
        const isNdl = store.getters.location === 'ndl' || store.getters.location === 'kss'// 館内PC
        if (props.item.permission.type === 'ooc' && store.getters.isPersendOrLibsend && !isNdl) {
          store.dispatch('sendLogToPms')
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )

    watch(currentContentId, async () => {
      // コンテンツ切り替え時にogImageを変更させるため
      store.commit('SET_DETEIL_VIEWER_MODE', {
        routeName: route.name,
        viewerType: viewerType.value,
        item: props.item,
        publicPath: store.getters.publicPath,
        store: store,
        isContentSelected: contentIndex.value !== '',
      })

      // コンテンツ切り替え時に同時閲覧制限チェックさせるため
      await store.dispatch('checkSimView', { type: bundlePermissionRule.value, action: 'simview' })
      await (async () => {
        // itemかbundleレベルで同時閲覧可能かをチェックする
        if (isAllowedSimview.value) {
          console.log(`simview check skip value = ${isAllowedSimview.value} `)
          return true
        }
        // 特別利用者のときも無条件trueを返す
        const isBuiltinUser = store.getters.loginType === 'builtin' // 特別利用者
        if (isBuiltinUser) {
          return true
        }
        // コンテンツのないアイテムの場合はtrueを返す。
        if (!currentBundle.value || !currentContent.value) {
          return true
        }
        await store.dispatch('canSimView', { pid: props.item.pid, bid: currentBundle.value.id, cid: currentContent.value.id })
        return store.getters.canSimView
      })().then((canSimViewValue) => {
        canSimView.value = canSimViewValue
        store.commit('CAN_SIMVIEW', canSimViewValue)
        if (!canSimViewValue) {
          addonViewResetToken.value++
        }
      })
    },
    {
      immediate: true,
    })

    // tokenは60分で有効期限が切れるため、50分ごとにリフレッシュする
    const TOKEN_INTERVAL_MINUTES: number = process.env.VUE_APP_TOKEN_INTERVAL_MINUTES
    const timerId = setInterval(async () => {
      try {
        AccessTokenService.setAccessTokenAsync(props.item.pid).then(viewConditionRefresh)
      } catch (e) {
        console.error('AccessTokenService.setAccessTokenAsync error 2')
        console.error(e)
        throw e
      }
    }, TOKEN_INTERVAL_MINUTES * 60 * 1000)

    onBeforeUnmount(() => {
      store.commit('clearContentData')
      if (isServer) return
      document.removeEventListener('keydown', getPressedKey)
      clearInterval(timerId)
    })

    onMounted(async () => {
      if (isServer) return
      document.addEventListener('keydown', getPressedKey)
    })

    let mainPaneWidth: string
    let sidePaneWidth: string

    const togglePane = () => {
      if (isServer) return
      const mainPane: HTMLElement | null = document.getElementById('viewer-main-pane')
      const sidePane: HTMLElement | null = document.getElementById('viewer-side-pane')
      if (!mainPane || !sidePane) {
        return
      }
      if (!mainPaneWidth || !sidePaneWidth) {
        // 退避
        mainPaneWidth = mainPane.style.width
        sidePaneWidth = sidePane.style.width
        // 閉じる
        mainPane.style.width = getPaneLeftMaxWidth() + '%'
        sidePane.style.width = getPaneRightMinWidth() + '%'
      } else {
        // 開く
        mainPane.style.width = mainPaneWidth
        sidePane.style.width = sidePaneWidth
        // 退避
        mainPaneWidth = ''
        sidePaneWidth = ''
      }
      paneResizeHandler()
    }

    /**
     * ショートカットキー
     */
    const preventStandardHotKeyActions = (event: any) => {
      event.stopPropagation()
      event.preventDefault()
    }

    const getPressedKey = (event: any) => {
      if (isServer) return
      const activeElement: any = document.activeElement
      // 共通ヘッダーの検索フォーム入力時はショートカットを無効化
      if (activeElement?.classList?.contains('keyword-search-input-form')) return
      // テキストフィールドの入力時はショートカットを無効化
      if (activeElement?.classList?.contains('app-input')) return
      // ラジオボタンの入力時はショートカットを無効化
      if (activeElement?.type === 'radio') return
      // レンジスライダーの入力時はショートカットを無効化
      if (activeElement?.classList?.contains('app-input-range')) return
      // モーダル表示時はショートカットを無効化
      if (document.getElementsByClassName('modal-window-container')?.length > 0) return

      // ブラウザのショートカットと競合するため
      if (event.ctrlKey || event.altKey) return
      const pressedKey = event.key
      let action: any
      switch (pressedKey) {
        case 'i':
          action = togglePane
          break
      }
      if (
        action &&
        pressedKey !== 'Control' &&
        pressedKey !== 'Alt' &&
        pressedKey !== 'Shift'
      ) {
        action()
        preventStandardHotKeyActions(event)
      }
    }

    provide('getMetadataValue', (id: number) => {
      const metadata = store.getters.MetadataObjectById(id)
      return props.item[metadata.field] || props.item.meta[metadata.field]
    })
    provide('getMetadataLabels', (id: number) => {
      const metadata = store.getters.MetadataObjectById(id)
      return { ja: metadata.label, en: metadata.label_en }
    })

    const currentContentsBundle = computed(() => store.getters.currentContentsBundle)
    const hasBundleMeta = computed(() => {
      if (!currentContentsBundle.value?.meta) return false
      return Object.values(currentContentsBundle.value.meta).some((val: any) => val && val[0])
    })

    const displayBundleMeta = computed(() => {
      const excludeBundleMetaKeyList = ['0601Dt', '0602Dt', '0603Dk']
      const displayBundleMetaKeyList = currentContentsBundle.value?.meta ? Object.keys(currentContentsBundle.value.meta).filter((key) => !excludeBundleMetaKeyList.includes(key)) : []
      return displayBundleMetaKeyList.reduce((accumulator: any, key: string) => {
        accumulator[key] = currentContentsBundle.value?.meta[key]
        return accumulator
      }, {})
    })

    const fullScreenHeader = ref(false)
    let timeOutId = 0

    const fullScreen = computed(() => {
      return store.getters.fullScreen
    })

    watch(fullScreen, (value) => {
      if (value) {
        fullScreenHeader.value = true
        timeOutId = setTimeout(() => {
          fullScreenHeader.value = false
        }, 2000)
      } else {
        fullScreenHeader.value = false
      }
      paneResizeHandler()
    })

    const showFullScreenHeader = () => {
      clearTimeout(timeOutId)
      fullScreenHeader.value = true
    }

    const hideFullScreenHeader = () => {
      if (isServer) return
      const activeElement = document.activeElement?.classList.contains('display-header-on-focus')
      if (!activeElement) {
        timeOutId = setTimeout(() => {
          fullScreenHeader.value = false
        }, 2000)
      }
    }

    const contentViewer = ref()

    const paneResizeHandler = () => {
      if (viewerType.value === 'TheImageViewer') {
        contentViewer.value.viewerSizeChanged()
      }
    }

    const clickAutoAdjust = () => {
      const delta = getAutoAdjustParameter(contentViewer.value.getImageData())
      store.dispatch('autoAdjust', delta)
    }

    const isHeadstoneItem = computed(() => {
      if (props.item.meta['0501Dk']?.[0] === '1') {
        store.commit('SET_ISHEADSTONEITEM', true)
        return true
      } else return false
    })

    return {
      isKn,
      isIframePage,
      viewerType,
      currentBundleIndex,
      isMobile,
      isDisable,
      state,
      detailContentViewSize,
      addonViewSize,
      addonViewMinSize,
      isBundlePermission,
      isContentPermission,
      showDownloadPanel,
      currentContentsBundle,
      lang,
      hasBundleMeta,
      fullScreen,
      fullScreenHeader,
      showFullScreenHeader,
      hideFullScreenHeader,
      canSimView,
      paneResizeHandler,
      contentViewer,
      clickAutoAdjust,
      isHeadstoneItem,
      displayBundleMeta,
      waitRestrictions,
      addonViewResetToken,
      showNotPublished,
      showNotFound,
    }
  },
})
