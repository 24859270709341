// TODO DodをDkに戻すこと（PI4の暫定対応でDodをDkで取り扱っている。0058Dod）

import { store } from '@/store'
import { sanitizeString } from '@/helpers/util/escapeCharacterUtils'

/**
 * 検索結果一覧のアイテム下部表示情報（タイトル・コレクション以外の表示領域）を返却します。
 * @param hitDocument SearchHits<item>のhits配列のオブジェクト
 * @returns (0003Dtct) / 0010Dtct(*3) (0020Dtct, 0058Dod(*4)) 掲載雑誌名: 0271Dt. 0272Dt
 */
export const getItemListViewContent = (hitDocument: any, store: any, lang: string) => {
  if (!hitDocument) return ''
  if (!hitDocument.content) return ''
  if (!hitDocument.content.meta) return ''
  const mainCollection = hitDocument.content.collections ? hitDocument.content.collections[0] : 'default'

  return createItemListViewValue(mainCollection, hitDocument.content.meta, store, lang)
}

/**
 * マイコレクションのアイテム下部表示情報（タイトル・コレクション以外の表示領域）を返却します。
 * @param item /mycollection/collection/listで返却された結果を詰めたオブジェクト
 * @returns (0003Dtct) / 0010Dtct(*3) (0020Dtct, 0058Dod(*4)) 掲載雑誌名: 0271Dt. 0272Dt
 */
export const getItemListViewContentOfMyCollection = (item: any, store: any, lang: string) => {
  if (!item.meta) return ''
  const mainCollection = item.collections ? item.collections[0] : 'default'

  return createItemListViewValue(mainCollection, item.meta, store, lang)
}

/**
 * アイテム下部表示情報（タイトル・コレクション以外の表示領域）を返却します。
 * @param collectionId 対象コレクションID
 * @param meta アイテムのメタデータ
 * @returns (0003Dtct) / 0010Dtct(*3) (0020Dtct, 0058Dod(*4)) 掲載雑誌名: 0271Dt. 0272Dt
 */
export const createItemListViewValue = (mainCollection: string, meta: any, store: any, lang: string) => {
  let label = ''
  switch (getParentCollectionId(mainCollection)) {
    // コレクション種別によって分岐を追加する

    case 'A00001': // 図書
    case 'A00003': // 古典籍資料
    case 'A00004': // 錦絵
    case 'A00152': // 地図
      label += getCreatorText(meta) // 著者
      label += addPrefixSuffix(' ', [getPublisherText(meta)]) // 出版者
      if (hasMeta(meta['0020Dtct']) && hasMeta(meta['0058Dod'])) label += ','
      label += addPrefixSuffix(' ', [metaArrayProcessing(meta['0058Dod'])]) // 出版年月日
      label += getPublicationName(meta, ['A00003', 'A00004'].includes(getParentCollectionId(mainCollection)) ? '収載資料名' : undefined) // 掲載雑誌名: {刊行物名}. 刊行物巻
      break

    case 'A00016': // 日本占領関係資料
    case 'A00019': // プランゲ文庫
      label += getCreatorText(meta) // 著者
      label += addPrefixSuffix(' ', [issuedArrayProcessing(meta, true)]) // 出版年月日|出版年月日（W3CDTF）
      break

    // 官報
    case 'A00015':
      label += addPrefixSuffix(' (', [getSeriesTitleText(meta)], ')')
      label += getCreatorText(meta)
      label += addPrefixSuffix(' (', [getPublisherText(meta), issuedArrayProcessing(meta)], ')')
      label += getPublicationName(meta)
      break

    // 憲政資料
    // 著者 (出版年月日), 数量等, 請求記号
    case 'A00017': {
      label += getCreatorText(meta)

      // 著者名以外のメタデータ
      const labels = [
        addPrefixSuffix('(', [issuedArrayProcessing(meta, true)], ')'),
        hasMeta(meta['0074Dt']) ? meta['0074Dt'][0] : '',
        metaArrayProcessing(meta['0371Dkck']),
      ].filter(x => x.length > 0) // メタデータが空の項目は消す

      if (label.length > 0 && labels.length > 0) label += ' ' // 著者とその他の項目の間はスペース
      label += labels.join(', ')
      break
    }

    // 歴史的音源
    case 'A00024':
      label += addPrefixSuffix(' (', [getSeriesTitleText(meta)], ')')
      label += addPrefixSuffix(' ', [metaArrayProcessing(meta['0010Dtct'])])
      label += addPrefixSuffix(' (', [getPublisherText(meta), getDescription(meta), issuedArrayProcessing(meta, true)], ')')
      label += getPublicationName(meta)
      break
    // 録音・映像関係資料 > 脚本
    case 'A00123':
      label += addPrefixSuffix(' ', [metaArrayProcessing(meta['0010Dtct'])])
      label += addPrefixSuffix(' (', [getPublisherText(meta), issuedArrayProcessing(meta, true)], ')')
      label += addPrefixSuffix(', ', [metaArrayProcessing(meta['0018Dtct'])])
      break
    // 録音・映像関係資料 > 手稿譜
    case 'A00124':
      label += addPrefixSuffix(' ', [metaArrayProcessing(meta['0010Dtct'])])
      label += addPrefixSuffix(' (', [issuedArrayProcessing(meta, true)], ')')
      break
    // 他機関デジタル化資料 > 科学映像
    case 'A00126':
      label += getCreatorText(meta)
      label += addPrefixSuffix(' (', [getPublisherText(meta, '; '), issuedArrayProcessing(meta, true)], ')')
      break
    default:
      // 2.0 (seriesTitle) / creator(*3) (publisher, issued(*4)) 掲載雑誌名: publicationName. publicationVolume
      // 3.0 (0003Dtct) / 0010Dtct(*3) (0020Dtct, 0058Dod(*4)) 掲載雑誌名: 0271Dt. 0272Dt
      // データがない場合何も表示させない
      label += addPrefixSuffix(' (', [getSeriesTitleText(meta)], ')')
      label += getCreatorText(meta)
      label += addPrefixSuffix(' (', [getPublisherText(meta), issuedArrayProcessing(meta, true)], ')')
      label += getPublicationName(meta)
  }

  // getParentCollectionId が返さないコレクションで下位コレクションを持たないものはこっちに書く
  switch (mainCollection) {
    case 'A00148': // 他機関デジタル化資料 > 内務省検閲発禁図書
      label = ''
      label += getCreatorText(meta) // 著者
      label += addPrefixSuffix(' ', [issuedArrayProcessing(meta, true)]) // 出版年月日|出版年月日（W3CDTF）
      break
  }

  //XSS対策としてサニタイジング
  label = sanitizeString(label)

  return label
}

export const getItemListViewSpot = (hitDocument: any) => {
  if (!hitDocument) return ''
  if (!hitDocument.content) return ''
  if (!hitDocument.content.meta) return ''

  const meta = hitDocument.content.meta

  let label = ''
  // 0010Dtct(*3) (0020Dtct, 0058Dod(*4))
  label += getCreatorText(meta)
  label += addPrefixSuffix(' (', [getPublisherText(meta), issuedArrayProcessing(meta)], ')')
  return label
}

const hasMeta = (meta: Array<string>): boolean => {
  if (!meta || meta[0] === '') return false // null or undefind or '' ならばfalse
  if (meta.length === 0) return false // 空配列ならfalse
  return true
}

const getSeriesTitleText = (meta: any): string => {
  if (!hasMeta(meta['0003Dtct'])) return ''
  return meta['0003Dtct'][0]
}

const getCreatorText = (meta: any): string => {
  if (!hasMeta(meta['0010Dtct'])) return ''
  if (meta['0010Dtct'].length === 1) return ' ' + meta['0010Dtct'].join()
  // TODO: 文頭の区切り文字付与は別メソッドでやるべき
  return ' ' + meta['0010Dtct'][0] + '[他]'
}

const getPublisherText = (meta: any, separator?: string): string => {
  if (!hasMeta(meta['0020Dtct'])) return ''
  return separator ? meta['0020Dtct'].join(separator) : meta['0020Dtct'][0]
}

/**
 * @param meta 各メタ項目を持つオブジェクト
 * @param w3cdtf 0058Dodがない場合にW3CDTFの0059Dkを表示させたいなら true
 * @returns 指定したメタ項目の連結結果
 */
export const issuedArrayProcessing = (meta: any, w3cdtf = false): string => {
  let issuedLabel = ''
  if (hasMeta(meta['0058Dod'])) issuedLabel += meta['0058Dod'][0]
  else if (w3cdtf && hasMeta(meta['0059Dk'])) issuedLabel += meta['0059Dk'].join(' - ')
  return issuedLabel
}

const getDescription = (meta: any): string => {
  if (!hasMeta(meta['0040Dtct'])) return ''
  return meta['0040Dtct'][0]
}

const getPublicationName = (meta: any, materialType = '掲載雑誌名'): string => {
  let publicationNameLabel = ''
  if (hasMeta(meta['0271Dt'])) {
    if (hasMeta(meta['0272Dt'])) {
      // TODO: 文頭の区切り文字付与は別メソッドでやるべき
      publicationNameLabel += ` ${materialType}: ` + meta['0271Dt'].join() + '. ' + meta['0272Dt'].join()
    } else {
      publicationNameLabel += ` ${materialType}: ` + meta['0271Dt'].join()
    }
  } else {
    if (hasMeta(meta['0272Dt'])) return ` ${materialType}: ` + '-. ' + meta['0272Dt'].join()
  }
  return publicationNameLabel
}

/**
 * @param meta 特定のメタ項目
 * @param sep 要素を連結する時の区切り文字（デフォルトはカンマ","）
 * @returns 渡された文字列を連結した結果
 */
const metaArrayProcessing = (meta: Array<string>, sep = ','): string => {
  if (!hasMeta(meta)) return ''
  return meta.join(sep)
}

/**
 * コレクションIDから親IDを返すメソッド
 * ※一部コレクションについてはそのもののIDを返す
 */
export const getParentCollectionId = (collectionId: string): string => {
  // 錦絵、録音・映像関係資料 > 脚本、録音・映像関係資料 > 手稿譜、他機関デジタル化資料 > 科学映像
  const exceptionCollectionId = ['A00004', 'A00123', 'A00124', 'A00126']
  if (exceptionCollectionId.includes(collectionId)) return collectionId
  return store.getters.CollectionFamily(collectionId)[1]?.collectionId || 'default'
}

/**
 * @param prefix 文章前につける文字列（例：直前の表示項目との間隔のための空白など）
 * @param text 表示したい文字列の配列
 * @param suffix 文章後ろにつける文字列
 * @returns 指定した文字列を連結した結果
 */
const addPrefixSuffix = (prefix = '', text: Array<string>, suffix = ''): string => {
  if (text.every((e: string) => e === '')) return ''
  // 配列内に空要素があれば削除する
  const trimedTextList = text.filter(Boolean)
  return `${prefix}${trimedTextList.join(', ')}${suffix}`
}
